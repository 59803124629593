import React from 'react'
import { AppBar, Box, Button, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, SvgIcon, Toolbar, Typography } from '@mui/material'
import { Link } from 'react-router-dom';
import Bars3Icon from '@heroicons/react/24/solid/Bars3Icon';

const drawerWidth = 240;
const navItems = ['Home', 'About', 'Contact', 'Privacy Policy', 'Shipping Policy', 'Refund Policy', 'Contact Information'];

function Header(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Link to="/">
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    <Typography
                        color="primary"
                        variant="h6"
                        sx={{ my: 2 }}
                    >
                        Chap Mall
                    </Typography>
                </Box>
            </Link>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item} disablePadding>
                        <ListItemButton>
                            <ListItemText primary={item} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <>
            <AppBar
                component="nav"
                position="sticky"
                style={{
                    backdropFilter: 'blur(6px)',
                    backgroundColor: '#FFFFFF',
                    top: 0,
                    zIndex: (theme) => theme.zIndex.appBar,
                }}
            >
                <Toolbar>
                    <IconButton
                        color="text.primary"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <SvgIcon fontSize='large'>
                            <Bars3Icon />
                        </SvgIcon>
                    </IconButton>
                    <Link to="/" style={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
                        <Typography
                            color="primary"
                            variant="h6"
                            component="div"
                            sx={{ display: { xs: 'none', sm: 'block' } }}
                        >
                            Chap Mall
                        </Typography>
                    </Link>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {navItems.map((item) => (
                            <Button key={item} sx={{ color: 'black' }}>
                                {item}
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
        </>
    )
}

export default Header